<template>
  <div class="root">
    <div class="video-container">
      <video
        class="video"
        :src="videos.inicio.default"
        type="video/webm"
        autoplay
        muted
        loop
      />
    </div>
    <div class="py-12 w-full">
      <h1 class="text-center text-5xl">Nuestros Servicios</h1>
      <hr class="mx-auto w-24" />
      <p class="mx-auto my-6 md:max-w-3xl px-4 text-center">
        Cada mañana, cuando abrimos las puertas, nos volcamos en dar soluciones
        a las singularidades de cada cliente haciendo que cobren su verdadero
        sentido los tres pilares de nuestra empresa: calidad, servicio y precio.
      </p>
      <div class="container mx-auto">
        <div
          class="
            flex flex-wrap
            space-x-4
            lg:flex-nowrap
            justify-center
            relative
          "
        >
          <Servicio
            v-for="(slide, index) in slider"
            :key="index"
            :servicio="slide"
          />
        </div>
      </div>
    </div>
    <div class="mvv bg-gray-100 w-full">
      <div class="container mx-auto py-16">
        <h1 class="text-center text-5xl mx-auto">Misión, Visión y Valores</h1>
        <hr class="mx-auto mb-16" />
        <div id="mvv-mobile" class="px-4 bg-gray-100">
          <div
            class="w-full group max-w-xl mb-4 mx-auto bg-gray-100 rounded-2x"
            v-for="(item, index) in mvv"
            :key="index"
          >
            <Disclosure v-slot="{ open }" :defaultOpen="index == 0">
              <DisclosureButton
                class="
                  flex
                  justify-between
                  items-center
                  w-full
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-left
                  bg-white
                  rounded-2xl
                  focus:outline-none
                  group-hover:shadow-md
                  transition
                  duration-300
                "
              >
                <div>
                  <span class="ml-8 text-xl font-black">
                    {{ item.title }}
                  </span>
                  <hr class="ml-8 mt-0 mb-2" />
                </div>
                <ChevronRightIcon
                  :class="open ? 'transform -rotate-90' : ''"
                  class="w-5 h-5 text-blue-500 self-center"
                />
              </DisclosureButton>
              <transition
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-300 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <DisclosurePanel
                  class="
                    rounded-xl
                    shadow
                    transition
                    duration-300
                    mt-2
                    py-8
                    px-12
                    text-base text-gray-900
                    font-serif
                    bg-white
                  "
                >
                  <ul>
                    <li class="mb-4" v-for="(dot, i) in item.dots" :key="i">
                      {{ dot }}
                    </li>
                  </ul>
                  <ul class="extra pt-4" v-if="item.extraDots">
                    <li
                      class="mb-2"
                      v-for="(extraDot, j) in item.extraDots"
                      :key="j"
                    >
                      <span>{{ extraDot }}</span>
                    </li>
                  </ul>
                </DisclosurePanel>
              </transition>
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto py-16" @scroll="onScrollNumbers">
      <h1 class="text-5xl text-center">Grupo LON en cifras</h1>
      <hr class="mx-auto" />
      <div
        id="numbers"
        class="pt-16 pb-4 flex flex-wrap justify-center gap-x-32"
      >
        <div class="number pb-12 max-w-xs" v-for="(n, i) in numbers" :key="i">
          <number
            animationPaused
            class="text-5xl font-black font-sans mb-6 text-blue-900"
            easing="Power3.easeOut"
            :ref="`${n.title}`"
            :from="0"
            :to="n.title"
            :duration="2"
          />
          <h2 class="text-xl text-blue-400 font-bold my-2">{{ n.subtitle }}</h2>
          <p>{{ n.description }}</p>
        </div>
      </div>
    </div>
    <div class="block w-full bg-blue-100">
      <div class="container mx-auto px-8 py-12">
        <div class="text-center md:text-left md:flex py-12 p-8">
          <img
            class="
              bg-white
              w-48
              h-48
              rounded-full
              mb-8
              p-8
              md:mb-auto
              my-auto
              mx-auto
              md:mr-12
              object-contain
            "
            src="@/assets/logo_dark.png"
            alt="img"
          />
          <div class="self-center">
            <h1 class="py-4 text-blue-900">Nuestros clientes nos avalan</h1>
            <p class="my-auto text-black">
              Nuestros más de 35 años de experiencia nos han permitido crecer
              con nuestro clientes. Ellos nos han ayudado a mejorar y su
              satisfacción es nuestro motivo de seguir. Por eso, cada día
              seguimos innovando y mejorando para ser líderes en el sector y
              ofrecer siempre la mejor calidad unido de la tecnología más
              puntera y el respeto al medioambiente.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grupo relative">
      <img
        class="
          lg:ml-96
          my-8
          lg:my-16
          h-72
          lg:h-auto
          object-cover
          slider-image
          w-full
          inicio-grupo-lon
        "
        src="@/assets/inicio_grupo_lon.jpg"
        alt=""
      />
      <div class="floating-text lg:absolute lg:bg-white lg:w-2/5 lg:pl-8">
        <div class="py-12 px-8">
          <h1>El Grupo LON</h1>
          <hr />
          <p>
            Desde hace 35 años somos referente en la Marina Baixa alicantina,
            cuyo epicentro económico es Benidorm. Nuestra empresa familiar es
            reconocida por su vocación de servicio y la cercanía con el cliente.
          </p>
          <br />
          <p class="mb-12">
            De la mano de la tecnología aplicamos mejoras continuas en los
            procesos de lavandería y renting textil, teniendo siempre presente
            el cuidado del planeta para dejar un mundo mejor a las siguientes
            generaciones.
          </p>
          <router-link to="/grupo" class="px-24 button">Ver más</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Servicio from "@/components/landing/Servicio.vue";
import "vue3-carousel/dist/carousel.css";
import { servicios, mvv, numbers, slider, videos } from "@/vars.js";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  name: "Landing",
  components: {
    Servicio,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    animateNumbers() {
      const refs = this.$refs;
      this.numbers.forEach(function (number) {
        const title = `${number.title}`;
        console.log(refs[title]);
        refs[title].play();
      });
    },
    onScroll() {
      if (!this.activated) {
        const offset = this.getElementOffset(
          document.getElementById("numbers")
        );
        if (offset - window.innerHeight <= window.pageYOffset) {
          this.activated = true;
          this.animateNumbers();
        }
      }
    },
    getSlideImage(slide) {
      const imgUrl = slide.img || "https://picsum.photos/seed/picsum/1280/720";
      return {
        backgroundImage: imgUrl,
      };
    },
    theFormat(number) {
      if (number > 99999) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return number;
      }
    },
    onScrollNumbers(elem) {
      console.log(elem);
    },
    getElementOffset(elem) {
      var location = 0;
      if (elem.offsetParent) {
        do {
          location += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      }
      return location >= 0 ? location : 0;
    },
  },
  setup() {
    return { servicios, mvv, numbers, slider, open, activated: false, videos };
  },
};
</script>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 3px #000;
}

.text-shadow-big {
  text-shadow: 4px 4px 6px #000;
}

.slider-1, .inicio-grupo-lon {
  background-color: #2e2e2e;
  background-size: cover;
  background-position: center center;
  object-position: center;
}

ul {
  list-style: none;
}

/* tweak li dots */
ul li::before {
  @apply text-blue-400 font-bold text-2xl float-left;
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  margin-top: -0.15em; /* Also needed for space (tweak if needed) */
  margin-left: -0.75em; /* Also needed for space (tweak if needed) */
}

ul.extra {
  @apply ml-16;
}

ul.extra li::before {
  @apply text-2xl float-left;
  content: "\268A"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  margin-top: -0.5em; /* Also needed for space (tweak if needed) */
  margin-left: -1.5em; /* Also needed for space (tweak if needed) */
}

.number > h1 {
  @apply text-5xl text-blue-900;
}

.slider-image {
  height: 24;
  object-position: 37% 20%;
}

@screen md {
  .slider-image {
    height: 48rem;
    object-position: center center;
  }
}

@screen lg {
  .floating-text {
    bottom: 2rem;
  }
}

.video-container {
  position: relative;
  height: calc(100vh - 5rem);
  width: 100%;
}

.video {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: auto;
  object-fit: cover;
  top: 50%;
  left: 50%;
  bottom: 0;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}
</style>
