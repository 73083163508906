<template>
  <div
    class="
      service
      flex
      flex-col
      m-4
      my-8
      md:w-72
      w-auto
    "
  >
    <div
      class="flex justify-center items-center text-center md:justify-start md:text-left pt-4 pb-12"
    >
      <img
        :src="imageUrl"
        alt="icon"
        class="block w-12 h-12 mr-4 flex-nowshrink"
      />
      <h1 class="text-2xl" v-html="formattedTitle"></h1>
    </div>
    <p class="font-serif mb-8 text-md text-center md:text-left">
      {{ servicio.descr }}
    </p>
    <router-link :to="servicio.to"
      class="button mt-auto text-center self-center md:mr-auto w-36 text-white rounded"
    >
      Ver más
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    servicio: {
      type: Object,
    },
  },
  computed: {
    imageUrl() {
      return this.servicio.icon;
    },
    formattedTitle() {
      const title =  this.servicio.titleShort || this.servicio.title
        return title.replace(" ", "<br>");
    },
  },
};
</script>
<style scoped>
.service > h1 {
  white-space: pre-wrap;
}
</style>
